function invocarClient(buscarColaboradorHuella) {
    // $( document ).ajaxStop(function() {
    //     $body.addClass("loading");
    // });
    firmarDocumento();

}

function googleTranslateElementInit() {
    $('document').ready(function () {

        // RESTYLE THE DROPDOWN MENU
        $('#google_translate_element').on("click", function () {
            
            // Change font family and color
            $("iframe").contents().find(".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *")
            .css({
                'color': '#00297c',
                'width': '150px'
            });

            $("iframe").contents().find('.goog-te-menu-value span').css ('display', 'none');

            // Change menu's padding
            $("iframe").contents().find('.goog-te-menu2-item-selected').css ('display', 'none');
                
            // Change menu's padding
            $("iframe").contents().find('.goog-te-menu2').css ('padding', '0px');
        
            // Change the padding of the languages
            $("iframe").contents().find('.goog-te-menu2-item div').css('padding', '15px');
            $("iframe").contents().find('.goog-te-menu2-item div').css ('text-transform', 'capitalize');
            $("iframe").contents().find('.goog-te-menu2-item div').css ('text-decoration', 'none');
            
            // Change the width of the languages
            $("iframe").contents().find('.goog-te-menu2-item').css('width', '100%');
            $("iframe").contents().find('td').css('width', '100%');
            $("iframe").contents().find('.goog-te-menu2-item').css ('text-align', 'right');
        
            // Change hover effects
            $("iframe").contents().find(".goog-te-menu2-item div").hover(function () {
                $(this).css('background-color', 'transparent').find('span.text').css('color', '#00297c');
                $(this).css('text-decoration', 'underline').find('span.text').css('color', 'none');
            }, function () {
                $(this).css('background-color', 'white').find('span.text').css('color', '#00297c');
                $(this).css('text-decoration', 'none').find('span.text').css('color', 'underline');
            });

            // Change Google's default blue border
            $("iframe").contents().find('.goog-te-menu2').css('border', 'none');

            // Change the iframe's box shadow
            $(".goog-te-menu-frame").css('box-shadow', '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3)');
            
            // Change the iframe's size and position?
            $(".goog-te-menu-frame").css({
                'height': '140px',
                'width': '180px',
                'box-shadow': '0px 20px 30px rgb(0 0 0 / 3%)',
                'border-bottom-left-radius': '5px',
                'border-bottom-right-radius': '5px'
            });
            // Change iframes's size
            $("iframe").contents().find('.goog-te-menu2').css({
                'height': '100%',
                'width': '100%'
            });
        });
    });

    jQuery('.lang-select').click(function() {
        var theLang = jQuery(this).attr('data-lang');
        jQuery('.goog-te-combo').val(theLang);
  
        //alert(jQuery(this).attr('href'));
        window.location = jQuery(this).attr('href');
        location.reload();
    });

    new google.translate.TranslateElement({pageLanguage: 'es', includedLanguages: 'es,en,fr,it', layout: google.translate.TranslateElement.InlineLayout.SIMPLE, gaTrack: true}, 'google_translate_element');
}
